<template>
      <main class="relative h-screen">
    <img src="../assets/lost.jpg" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover object-top" />
    <div class="mx-auto absolute inset-0 -z-10 max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
      <p class="text-base font-semibold leading-8 text-white">404</p>
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">Page not found</h1>
      <p class="mt-4 text-base text-gray-800 sm:mt-6">Sorry, looks like the page you requested has taken a day off.</p>
      <div class="mt-10 flex justify-center">
        <a @click="goHome" class="cursor-pointer text-sm font-semibold leading-7 text-gray-900"><span aria-hidden="true">&larr;</span> Back to home</a>
      </div>
    </div>
  </main>
  </template>  

<script>

export default {
name: 'NotFound',

methods: {
  goHome() {
    this.$router.push(`/`)
  }
}

}
</script>
