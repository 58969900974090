<template>
  <div class="bg-gray-900 py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
    <div class="relative max-w-xl mx-auto">
      <svg class="absolute left-full transform translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-blue-500" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <svg class="absolute right-full bottom-0 transform -translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-white" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <div v-if="!success" class="text-center">
        <h2 class="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">Send us a message!</h2>
        <p class="mt-4 text-lg leading-6 text-gray-50">We provide real answers to questions. We will never try to sell you something you do not want, need, or that we know will not work in your environment.</p>
      </div>
      <div v-if="success" class="text-center">
        <h2 class="text-3xl font-extrabold tracking-tight text-blue-500 sm:text-4xl">Your message has been sent!</h2>
        <p class="mt-4 text-lg leading-6 text-gray-50">We will get back to you as soon as possible. In the meantime, check out our blog posts! We think they are pretty cool. </p>
      </div>
      <div class="mt-12">
        <form v-if="!success" @submit.prevent="sendMessage" ref="form" class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <div>
            <label for="first-name" class="block text-sm font-medium text-gray-50">Name</label>
            <div class="mt-1">
              <input v-model="name" type="text" required="" name="name" id="name" autocomplete="name" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" placeholder="Your name" />
            </div>
          </div>
          <div>
            <label for="company" class="block text-sm font-medium text-gray-50">Company</label>
            <div class="mt-1">
              <input v-model="company" type="text" name="company" id="company" autocomplete="organization" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" placeholder="Your company (optional)"/>
            </div>
          </div>
            <div v-if="submitted && v$.name.$invalid" class="sm:col-span-2 rounded-md bg-red-50 p-4">
                <div class="flex ">
                <div class="flex-shrink-0">
                    <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <div class="text-sm text-red-700">
                        A name is required...any name. We need to know how to address you. 
                    </div>
                </div>
                </div>
            </div>
          <div class="sm:col-span-2">
            <label for="email" class="block text-sm font-medium text-gray-50">Email</label>
            <div class="mt-1">
              <input v-model="email" id="email" name="email" type="email" autocomplete="email" placeholder="example@email.com" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
            <div v-if="submitted && v$.email.$invalid" class="sm:col-span-2 rounded-md bg-red-50 p-4">
                <div class="flex ">
                <div class="flex-shrink-0">
                    <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <div class="text-sm text-red-700">
                        We can't respond to your message if we don't know how to contact you. Please, enter your email address.
                    </div>
                    <div v-if="v$.email.$errors[1]" class="text-sm text-red-700">
                        The email you entered is not valid.
                    </div>
                </div>
                </div>
            </div>
          <div class="sm:col-span-2">
            <label for="message" required="" class="block text-sm font-medium text-gray-50">Message</label>
            <div class="mt-1">
              <textarea v-model="message" id="message" name="message" cols="30" rows="4" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md" placeholder="Your message"/>
            </div>
          </div>
            <div v-if="submitted && v$.message.$invalid" class="sm:col-span-2 rounded-md bg-red-50 p-4">
                <div class="flex ">
                <div class="flex-shrink-0">
                    <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <div class="text-sm text-red-700">
                        We know you can't wait to talk to us, but don't forget to write your message.
                    </div>
                </div>
                </div>
            </div>
          <div class="sm:col-span-2">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <Switch v-model="agreed" :class="[agreed ? 'bg-blue-600' : 'bg-gray-100', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                  <span class="sr-only">Agree to policies</span>
                  <span aria-hidden="true" :class="[agreed ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
              </div>
              <div class="ml-3">
                <p class="text-base text-gray-50">
                  By selecting this, you agree to the
                  {{ ' ' }}
                  <a href="/privacy-policy" class="font-medium text-blue-500 underline">Privacy Policy</a>
                  {{ ' ' }}
                  and
                  {{ ' ' }}
                  <a href="/cookie-policy" class="font-medium text-blue-500 underline">Cookie Policy</a>.
                </p>
              </div>
            </div>
            <div v-if="submitted && !v$.agreed.$model" class="sm:col-span-2 mt-4 rounded-md bg-red-50 p-4">
                <div class="flex">
                <div class="flex-shrink-0">
                    <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <div class="text-sm text-red-700">
                        Did you read our Privacy and Cookie policies? 
                    </div>
                </div>
                </div>
            </div>
          </div>
          <div class="sm:col-span-2">
            <ReCAPTCHA ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" size="invisible" sitekey="6LdNcK0eAAAAAAdUdk4FbnpTdY4oakZvGbusRN-l" />
            <button type="submit" class="w-full font-bold inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base text-white bg-blue-600 hover:bg-gray-900 hover:text-blue-500">Let's talk</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Switch } from '@headlessui/vue';
import useValidate from "@vuelidate/core";
import { required, minLength, sameAs, email } from "@vuelidate/validators";
import emailjs from 'emailjs-com';
import { XCircleIcon } from '@heroicons/vue/solid';
import ReCAPTCHA from './ReCAPTCHA.vue'

export default {
  name: 'ContactUs',
  
  components: {
    Switch,
    XCircleIcon,
    ReCAPTCHA
  },
  data() {
      return {
          agreed: false,
          name: "",
          email: "",
          message: "",
          company: "",
          submitted: false,
          v$: useValidate(),
          success: false,
          error: "",

      }
  },
  validations() {
      return {
        name: { required },
        email: { required, email },
        message: { required, minLength: minLength(8) },
        agreed: { sameAs: sameAs(() => true )},
        }
    },

  methods: {

    sendMessage() {
        this.submitted = !this.submitted
        this.v$.$touch()
        if (this.submitted && this.v$.name.$invalid || this.v$.email.$invalid || !this.v$.agreed.$model || this.v$.message.$invalid) {
            return;
        }
        try {
            //emailjs.sendForm(process.env.VUE_APP_EMAILJS_SERVICE, process.env.VUE_APP_EMAILJS_TEMPLATE, this.$refs.form, process.env.VUE_APP_EMAILJS_SECRET)
            emailjs.sendForm('service_msd9vu8', 'template_0ii74ta', this.$refs.form, 'p2JKsQJAHT_NUv4lH')
            this.submitted = false
        
        } catch(error) {
            console.log({error})
            this.submitted = false
        }
        if(!this.error) {
            // Reset form field
            this.success = true
            this.name = ''
            this.email = ''
            this.message = ''
            this.submitted = false
        }
    },

    validations() {
        return {};
    }
  },

}
</script>