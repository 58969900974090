<template>
  <div v-if="!hide" class="relative bg-gray-900 overflow-hidden flex flex-col ">
    <div >
    <main>
      <div class="pt-4 bg-gray-900 sm:pt-16 lg:pt-12 lg:pb-8 lg:overflow-hidden">
        <div class="mx-auto max-w-7xl lg:px-8">
          <div class="lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
              <div class="lg:py-28">
                <a class="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">
                  <span class="px-3 py-0.5 text-gray-900 text-xs font-semibold leading-5 uppercase tracking-wide bg-blue-500 rounded-full">Need Help?</span>
                  <span @click="openContactForm('contact')" class="ml-4 text-sm cursor-pointer">Send us a message</span>
                  <ChevronRightIcon class="ml-2 w-5 h-5 text-gray-500" aria-hidden="true" />
                </a>
                <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                  <span class="block">A
                  <span class="text-blue-500">better </span>
                  <span>way</span></span>
                  <span class="block">to cybersecurity</span>
                </h1>
                <p class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">We bring quality, sensible, and personalized cybersecurity services to underserved markets, like the "Big Four", minus the cost and frills.</p>
                <div class="mt-10 sm:mt-12">
                  <form action="#" class="sm:max-w-xl sm:mx-auto lg:mx-0">
                    <div class="sm:flex">
<!--                       <div class="min-w-0 flex-1">
                        <label for="email" class="sr-only">Email address</label>
                        <input id="email" type="email" placeholder="Enter your email" class="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900" />
                      </div> -->
                      <div class="mt-3 sm:mt-0 sm:ml-0">
                        <button @click="startConsultation" type="button" class="block w-full py-3 px-4 rounded-md shadow bg-blue-600 text-gray-900 font-medium hover:bg-gray-900  hover:text-white hover:border-gray-50 ">Schedule a free consultation</button>
                      </div>
                    </div>
                    <p class="mt-3 text-xs text-gray-300 sm:mt-4">Start your free 30-minute consultation, no obligation. We will collect your email address to send you a meeting invitation. We will never sell your personal information or send you unsolicited emails.</p>
                  </form>
                </div>
              </div>
            </div>
            <div class="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                <img class="flex object-contain hover:object-scale-down lg:absolute lg:inset-y-0 lg:-left-16 lg:h-full lg:w-auto lg:max-w-none" src="../assets/bccover.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-auto mt-16 pt-16 pb-16 mb-16 max-w-7xl px-6 sm:mt-16 lg:px-8">
        <h2 class="mt-4 text-lg tracking-tight font-bold sm:mt-5 sm:text-lg lg:mt-6 xl:text-2xl text-gray-50">Our Partners</h2>
        <div class="flex justify-center mt-10">
          <img class="max-h-6 w-full opacity-50 object-contain" src="../assets/dratalogo.png" alt="Transistor" width="158" height="48" />
        </div>
      </div>
    </main>
    <Banner />
    <ConsultationDisclaimer v-if="consultation" :consultation="this.consultation" @redirect="closeConsultation($event)"/>
    <div ref="contactAnchor" >
    <ContactUs  v-if="contactForm" />
    </div>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon } from '@heroicons/vue/solid'
import Banner from './Banner.vue'
import ConsultationDisclaimer from './ConsultationDisclaimer.vue'
import ContactUs from './ContactUs.vue'

const navigation = [
  { name: 'Services', href: '#' },
  { name: 'Products', href: '#' },
  { name: 'Company', href: '#' },
  { name: 'Blog', href: '#' }
]

export default {
  components: {
    ChevronRightIcon,
    Banner,
    ConsultationDisclaimer,
    ContactUs,
  },
  props: {
    closeModal: Boolean,
    redirect: Boolean,
    spinner: Boolean,
  },

  data() {
    return {
      openItem: false,
      consultation: false,
      contactForm: false,
      hide: false,
    }
  },

  methods: {
    openMenuItem() {
      this.openItem = !this.openItem
    },
    closeMenuItem() {
      this.openItem = false
    },
    startConsultation() {
      this.consultation = true
    },
    closeConsultation() {
      this.consultation = false      
    },
    async openContactForm(to) {
      this.contactForm = true
  
     const anchors = {
        contact: this.$refs.contactAnchor
      }
      setTimeout(() => anchors[to].scrollIntoView({behavior: "smooth"}), 10);
    },
    openSpinner() {
      this.hide = true
    }

  },

  setup() {
    return {
      navigation,
    }
  },
}
</script>