<template>
  <div>
    <EngineeringAndOperations />
  </div>
</template>

<script>
import EngineeringAndOperations from '@/components/EngineeringAndOperations.vue'

export default {
  name: 'EngineeringAndOperationsPage',
  components: {
    EngineeringAndOperations
  }
}
</script>
