<template>
  <div v-if="!hide" class="flex flex-col relative py-36 overflow-hidden bg-gray-900">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg class="absolute top-28 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-blue-500" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-blue-500" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-white" fill="currentColor" />
            </pattern>
          </defs>
        </svg>
      </div>
    </div>
    <div class="text-lg max-w-prose mx-auto relative px-4 sm:px-6 lg:px-8">
        <h1>
          <span class="mt-2 block text-xl text-center leading-8 font-extrabold uppercase tracking-tight text-blue-500 sm:text-3xl">Our cookie policy for this website</span>
        </h1>
        <p class="mt-8 text-xl text-gray-50 leading-8">This policy is effective as of <strong>March 3, 2022</strong>, and was last reviewed and updated on April 8, 2023. </p>
        <h2 class="text-blue-500 mt-8 font-bold text-2xl">What types of cookies does the website use?</h2>
      </div>
      <div class="mt-6 prose prose-indigo prose-lg text-white mx-auto">

        <p>This website does not use any cookies, including first and third-party cookies.</p>
        <p>We will not place cookies and other similar information on any device visiting this website. We will not collect any information about your device, including IP addresses, operating system, identifiers, and location.</p>
      </div>
      <div >
        <h2 class="text-blue-500 mt-8 font-bold text-2xl">Why don't we use cookies and similar session tracking technologies?</h2>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">Our only service offering is cybersecurity, and we will not create secondary revenue streams by monetizing our potential customers' information.</p>
      </div>
    </div>
</template>

<script>

export default {
    name: 'CookiePolicy',
    components: {

    },
    data() {
        return {
            hide: false
        }
    },
    props: {
        spinner: Boolean,
    },
    methods: {
        openSpinner() {
            this.hide = true
    }
    }
}
</script>

