<template>
  <div>
    <ProductIdeation />
  </div>
</template>

<script>
import ProductIdeation from '@/components/ProductIdeation.vue'

export default {
  name: 'ProductIdeationPage',
  components: {
    ProductIdeation
  }
}
</script>
