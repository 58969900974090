<template>
  <div>
    <ExposureManagement />
  </div>
</template>

<script>
import ExposureManagement from '@/components/ExposureManagement.vue'

export default {
  name: 'ExposureManagementPage',
  components: {
    ExposureManagement
  }
}
</script>
