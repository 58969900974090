
<template>
    <div class="bg-gray-900">
  
      <main class="relative isolate">
        <!-- Background -->
        <div class="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl" aria-hidden="true">
          <div class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-50" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
        </div>
  
        <!-- Header section -->
        <div class="px-6 lg:px-8">
          <div class="mx-auto max-w-4xl pt-24 text-center sm:pt-40">
            <h2 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Cybersecurity for <span class="text-blue-500">untapped</span> markets</h2>
            <p class="mt-6 text-lg leading-8 text-gray-300">We offer tailored and cost-effective cybersecurity services that cater to the unique needs of small and mid-market businesses and startups, maximizing the value of their investments while minimizing their risks.</p>
          </div>
        </div>
  
        <!-- Content section -->
        <div class="mx-auto mt-20 max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <div class="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 lg:max-w-none lg:grid-cols-2">
              <div>
                <p>We exist to provide micro, small, and mid-sized businesses, as well as startups, with efficient, personalized, and affordable cybersecurity solutions. We are committed to delivering high-quality services to previously underserved markets, at a fraction of the cost charged by industry leaders.</p>
              </div>
              <div>
                <p>We operate globally and serve all industries. We are different from the conventional cybersecurity firm, and our history of exceeding our client's expectations and providing unparalleled satisfaction proves it.</p>
                <p class="mt-8">Our client relationships are paramount; we don't have to answer to investors.</p>
              </div>
            </div>
            <dl class="mt-16 grid grid-cols-1 gap-x-8 gap-y-12 sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mt-28 lg:grid-cols-4">
              <div v-for="(stat, statIdx) in stats" :key="statIdx" class="flex flex-col-reverse gap-y-3 border-l border-white/20 pl-6">
                <dt class="text-base leading-7 text-gray-300">{{ stat.label }}</dt>
                <dd class="text-3xl font-semibold tracking-tight text-white">{{ stat.value }}</dd>
              </div>
            </dl>
          </div>
        </div>
  
        <!-- Image section -->
        <div class="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
          <img src="../assets/wearedifferent.jpg" alt="" class="aspect-[9/4] w-full object-cover xl:rounded-3xl" />
        </div>
  
        <!-- Values section -->
        <div class="mx-auto text-left mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div class="mx-auto max-w-2xl lg:mx-0">
            <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our values</h2>
            <p class="mt-6 text-lg leading-8 text-gray-300">At the heart of our work lie six basic principles that guide our approach.</p>
          </div>
          <dl class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
            <div v-for="value in values" :key="value.name" class="relative pl-9">
              <dt class="inline font-semibold text-white">
                <font-awesome-icon :icon="value.icon" class="absolute left-1 top-1 h-6 w-6 text-blue-500" aria-hidden="true" />
                {{ value.name }}
              </dt>
              {{ ' ' }}
              <dd class="inline">{{ value.description }}</dd>
            </div>
          </dl>
        </div>
  
  
        <!-- CTA section -->
        <div class="relative isolate -z-10 mt-32 sm:mt-40">
          <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
              <img class="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm" src="../assets/joinus.jpg" alt="" />
              <div class="w-full flex-auto">
                <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Join our team</h2>
                <p class="mt-6 text-lg leading-8 text-gray-300">We're constantly looking for experienced and reputable cybersecurity experts to join our team on an exclusive contract basis.</p>
                <ul role="list" class="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2">
                  <li v-for="benefit in benefits" :key="benefit" class="flex gap-x-3">
                    <CheckCircleIcon class="h-7 w-5 flex-none" aria-hidden="true" />
                    {{ benefit }}
                  </li>
                </ul>
                <div class="mt-10 flex">
                  <a @click="openDisclaimer" class="cursor-pointer text-sm font-semibold leading-6 text-blue-500">See our job postings <span aria-hidden="true">&rarr;</span></a>
                </div>
              </div>
            </div>
          </div>
          <div class="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl" aria-hidden="true">
            <div class="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
          </div>
        </div>
      </main>
      <LinkedInJobDisclaimer v-if="linkedin" :linkedin="this.linkedin" @redirect="closeLinkedIn($event)"/>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import LinkedInJobDisclaimer from './LinkedInJobDisclaimer.vue'
  import {
    CheckCircleIcon,
  } from '@heroicons/vue/solid'
  
  export default {
  name: 'AboutUs',

  components: {
    CheckCircleIcon,
    LinkedInJobDisclaimer

  },

  data() {
      return {
        hide: false,
        stats: [
            { label: 'Business was founded', value: '2019' },
            { label: 'People on the team', value: '5+' },
            { label: 'Clients we helped', value: '88+' },
            { label: 'People follow us', value: '10k+' },
      ],
        values: [
        {
        name: 'Be genuine.',
        description: 'We never push unnecessary products, use high-pressure sales tactics, or exaggerate the benefits of our services.',
        icon: 'fa-duotone fa-stars',
        },
        {
        name: 'Be transparent.',
        description: 'Our pricing model is simple and fair; you never pay more than we quote.',
        icon: 'fa-duotone fa-hand-holding-dollar',
        },
        {
        name: 'Be supportive.',
        description: 'Our customers\' needs and budgets are our main priority.',
        icon: 'fa-duotone fa-handshake-angle',
        },
        {
        name: 'Share what we know.',
        description: 'Your initial consultation is always free. We don\'t charge to answer questions.',
        icon: 'fa-duotone fa-graduation-cap',
        },
        {
        name: 'Bring the best of the best.',
        description: 'We bring top-quality talent to every project.',
        icon: 'fa-duotone fa-sun-bright',
        },
        {
        name: 'Be humble.',
        description: 'We know our limitations and recognize that knowledge is endless.',
        icon: 'fa-duotone fa-shield-heart',
        },
    ],
    benefits: [
        'Competitive compensation',
        'Set your own schedule',
        'Create without constraints',
        'Choose your own work location',
        ],
    linkedin: false

      }
    },
    props: {
        spinner: Boolean,
    },

    methods: {
        openSpinner() {
            this.hide = true
    },
        closeLinkedIn() {
            this.linkedin = false
          },
        openDisclaimer() {
              this.linkedin = true
          }
    },
  
  setup() {
  const mobileMenuOpen = ref(false)

  return {
    mobileMenuOpen
  }
  }
}
</script>