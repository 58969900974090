<template>
  <PrivacyPolicy />
</template>

<script>
import PrivacyPolicy from '../components/PrivacyPolicy.vue'
  export default {
        name: 'PrivacyPolicyPage',
        components: {
          PrivacyPolicy
        }
    
  }
</script>

<style lang="scss" scoped>

</style>
