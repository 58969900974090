import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/tailwind.css'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faListCheck, faPeopleGroup, faArrowTrendUp, faBullhorn, faAt, faLaptopFile, faRectanglesMixed, faGlobe, faCloudExclamation, faCashRegister, faScrewdriverWrench, faBadgeCheck, faBrowser, faShieldKeyhole, faVirus, faEye, faMapLocationDot, faBook, faChartMixed, faInfinity, faSirenOn, faBookOpenReader, faPenNib, faSquareExclamation, faVialCircleCheck, faMagnifyingGlassChart, faCloudCheck, faLightbulbExclamationOn, faShieldVirus , faFireExtinguisher, faGears, faCircleInfo, faRocketLaunch, faTrianglePersonDigging, faStars, faHandHoldingDollar, faSunBright, faGraduationCap, faHandshakeAngle, faShieldHeart, faEllipsisVertical, faSiren, faBrakeWarning } from '@fortawesome/pro-duotone-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

const app = createApp(App);


app.use(router)
app.mount('#app')
app.component('font-awesome-icon', FontAwesomeIcon)
library.add( faListCheck, faPeopleGroup, faArrowTrendUp, faBullhorn, faAt, faLaptopFile, faRectanglesMixed, faGlobe, faCloudExclamation, faCashRegister, faScrewdriverWrench, faBadgeCheck, faBrowser, faShieldKeyhole, faVirus, faEye, faMapLocationDot, faBook, faChartMixed, faInfinity, faSirenOn,faBookOpenReader, faPenNib, faSquareExclamation, faVialCircleCheck, faMagnifyingGlassChart, faCloudCheck, faLightbulbExclamationOn, faShieldVirus, faFireExtinguisher, faGears, faCircleInfo, faRocketLaunch, faTrianglePersonDigging, faStars, faHandHoldingDollar, faSunBright, faGraduationCap, faHandshakeAngle, faShieldHeart, faSiren, faEllipsisVertical, faBrakeWarning)