<template>
  <div v-if="!hide" class="flex flex-col relative py-36 overflow-hidden bg-gray-900">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-blue-500" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-blue-500" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-blue-500" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
      </div>
    </div>
    <div class="text-lg max-w-prose mx-auto relative px-4 sm:px-6 lg:px-8">
        <h1>
          <span class="mt-2 block text-xl text-center leading-8 font-extrabold uppercase tracking-tight text-blue-500 sm:text-3xl">Our privacy policy for this website</span>
        </h1>
        <p class="mt-8 text-xl text-gray-50 leading-8">This policy is effective as of <strong>March 3, 2022</strong>, and was last reviewed and updated on April 8, 2023. </p>
      </div>
      <div class="mt-6 prose prose-indigo prose-lg text-white mx-auto">

        <p>This Privacy Policy describes our policies and procedures on collecting, using, and disclosing your information and tells you about your privacy rights and how the law protects you when you visit our website and schedule your free consultation.</p>
        <p>We use your personal information to provide the services offered by our website, but not to improve our website's services or performance.</p>
      </div>
      <div >
        <h2 class="text-blue-500 mt-8 font-bold text-2xl">Collecting and Using Your Personal Data</h2>
        <h3 class="text-white mt-8 font-bold text-xl">Types of Information Collected</h3>
        <h4 class="text-white uppercase mt-8 font-bold text-xl">Personal Information</h4>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">While scheduling a free consultation, we will ask you to provide us with certain personally identifiable information that we can use to contact or identify you. Personally identifiable information includes, but is not limited to:</p>
        <ul class="mt-6 prose prose-indigo prose-lg text-white mx-auto" role="list">
            <li>-  Email address</li>
            <li>-  First name and Last name</li>
        </ul>
      </div>
      <div >
        <h3 class="text-blue-500 mt-8 font-bold text-2xl">Use of Your Personal Information</h3>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">We will use your personal information only for the following purposes:</p>
        <ul class="mt-6 prose prose-indigo prose-lg text-white mx-auto" role="list">
        <li class="mt-6 prose prose-indigo prose-lg text-white mx-auto">-  To contact you: To contact you by email regarding an inquiry submitted through our website's contact form. </li>
        <li class="mt-6 prose prose-indigo prose-lg text-white mx-auto">-  To manage your free consultation: To send you a meeting invitation by email when scheduling a free consultation through our website. While we use Calendly to schedule free consultations, we are not affiliated with them and do not control or influence their privacy, cookie, and terms of use policies.  </li>
        </ul>
       <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">We will never share your personal information with anyone. </p>
      </div>
      <div >
        <h3 class="text-blue-500 mt-8 font-bold text-2xl">Retention of Your Personal Information</h3>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">We will retain your personal information only if you decide to acquire our services. In that case,  we will retain and use your personal information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.</p>
      </div>
      <div >
        <h3 class="text-blue-500 mt-8 font-bold text-2xl">Transfer of Your Personal Information</h3>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">Your personal information is processed in the United States at our operating locations. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction.</p>
       <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">Your consent to this Privacy Policy followed by your submission of such information represents Your agreement to that transfer. </p>
       <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">We will take all steps reasonably necessary to ensure that your data is treated securely and following this Privacy Policy.</p>
      </div>
      <div >
        <h3 class="text-blue-500 mt-8 font-bold text-2xl">Disclosure of Your Personal Information</h3>
        <h4 class="text-white uppercase mt-8 font-bold text-xl">Business Transactions</h4>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">If we are involved in a merger, acquisition, or asset sale, we may transfer your personal information. We will provide notice before your personal information is transferred and becomes subject to a different Privacy Policy.</p>
      </div>
      <div >
        <h4 class="text-white uppercase mt-8 font-bold text-xl">Law enforcement</h4>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">Under certain circumstances, we may be required to disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</p>
      </div>
      <div >
        <h4 class="text-white uppercase mt-8 font-bold text-xl">Other legal requirements</h4>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">We may disclose your personal information in the good faith belief that such action is necessary to:</p>
        <ul class="mt-6 prose prose-indigo prose-lg text-white mx-auto" role="list">
        <li>-  Comply with a legal obligation</li>
        <li>-  Protect and defend the rights or property of our company</li>
        <li>-  Prevent or investigate possible wrongdoing in connection with the services offered through our website</li>
        <li>-  Protect the personal safety of our website's visitors or the public</li>
        <li>-  Protect against legal liability</li>
        </ul>
      </div>
      <div >
        <h4 class="text-blue-500 mt-8 font-bold text-2xl">Security of Your Personal Data</h4>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">The security of personal information is important to us, but remember that no method of transmission over the Internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, We cannot guarantee its absolute security.</p>
      </div>
     <div >
        <h3 class="text-blue-500 mt-8 font-bold text-2xl">Children's Privacy</h3>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">Our services do not address anyone under the age of 18, and we do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and know that your child has provided us with personal information, please get in touch with us. If we become aware that we have collected personal information from anyone under the age of 18 without verification of parental consent, we will take steps to remove that information from our servers.</p>
      </div>
     <div >
        <h3 class="text-blue-500 mt-8 font-bold text-2xl">Links to Other Websites</h3>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">Our website contains links to other websites that we do not operate. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and Cookie Policy of every website you visit.</p>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">We have no control over and assume no responsibility for any third-party websites or services' content, privacy policies, or practices.</p>
      </div>
     <div >
        <h3 class="text-blue-500 mt-8 font-bold text-2xl">Changes to this Privacy Policy</h3>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">We will notify you via prominent notice on our website before the change becomes effective and update the "effective" date at the top of this Privacy Policy.</p>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
      </div>
     <div >
        <h3 class="text-blue-500 mt-8 font-bold text-2xl">Contact Us</h3>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">If you have any questions about this Privacy Policy, You can contact us:</p>
        <p class="mt-6 prose prose-indigo prose-lg text-white mx-auto">By email: admin@bettercyberllc.com</p>
      </div>
    </div>

</template>

<script>

export default {
    name: 'PrivacyPolicy', 
    components: {

    },
    data() {
        return {
            hide: false
        }
    },
    props: {
        spinner: Boolean,
    },
    methods: {
        openSpinner() {
            this.hide = true
    }
    }
}
</script>

