<template>
  <div class="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
    <div class="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56" aria-hidden="true">
      <div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#3bebb0] to-[#1b382e] opacity-30" style="clip-path: polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)" />
    </div>
    <div class="text-left mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <p class="text-lg font-semibold leading-8 tracking-tight text-blue-500">Shield your business</p>
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Exposure Management</h1>
        <p class="mt-6 text-xl text-left leading-8 text-gray-300">Our exposure management service conducts thorough assessments of your applications and the underlying infrastructure to uncover any weak points that could be exploited by cybercriminals.</p>
      </div>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
        <div class="relative lg:order-last lg:col-span-5">
          <svg class="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]" aria-hidden="true">
            <defs>
              <pattern id="e87443c8-56e4-4c20-9111-55b82fa704e3" width="200" height="200" patternUnits="userSpaceOnUse">
                <path d="M0.5 0V200M200 0.5L0 0.499983" />
              </pattern>
            </defs>
            <rect width="100%" height="100%" stroke-width="0" fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" />
          </svg>
          <figure class="border-l border-blue-500 pl-8">
            <blockquote class="text-xl font-semibold leading-8 tracking-tight text-gray-300">
              <p>“Exposure can be a double-edged sword. It can lead to great success or great failure.”</p>
            </blockquote>
            <figcaption class="mt-8 flex gap-x-4">
              <div class="text-sm leading-6">
                <div class="font-semibold text-white">Steven Spielberg</div>
              </div>
            </figcaption>
          </figure>
        </div>
        <div class="max-w-xl text-left text-base leading-7 text-gray-300 lg:col-span-7">
          <p>We offer comprehensive services, including web application penetration testing, technical cybersecurity assessments, and cybersecurity remediation. Our goal with this service is to provide the following:</p>
          <ul role="list" class="mt-8 text-left max-w-xl space-y-8 text-gray-300">
            <li class="flex gap-x-3">
              <font-awesome-icon icon="fa-duotone fa-browser" class="mt-1 h-5 w-5 flex-none text-blue-500" aria-hidden="true" />
              <span> <strong class="font-semibold text-white">Web application penetration testing </strong> to thorough assess of the security of your web applications. Our team uses both manual and automated testing methods to simulate internal and external attacks, providing you with a comprehensive view of your security posture. It's important to note that we do not offer network penetration testing as part of this service.</span>
            </li>
            <li class="flex gap-x-3">
              <font-awesome-icon icon="fa-duotone fa-badge-check" class="mt-1 h-5 w-5 flex-none text-blue-500" aria-hidden="true" />
              <span> <strong class="font-semibold text-white">Technical cybersecurity assessments </strong> that involve a comprehensive evaluation of our client's IT environments, utilizing industry-standard security best practices and benchmarks to identify potential vulnerabilities and risks. Through a hands-on, in-depth review process, we aim to provide our clients with a realistic and actionable plan to improve their security posture. </span>
            </li>
            <li class="flex gap-x-3">
              <font-awesome-icon icon="fa-duotone fa-screwdriver-wrench"  class="mt-1 h-5 w-5 flex-none text-blue-500" aria-hidden="true" />
              <span> <strong class="font-semibold text-white">Cybersecurity remediation </strong> that aims to address any security issues identified through independent security assessments, audits, or self-discovery within our clients' IT environment. Our service is designed to provide clients with the support and resources they need to promptly address any vulnerabilities or weaknesses and ensure their systems are secure.</span>
            </li>
          </ul>
          <div class="mt-10 flex">
            <a  @click="startConsultation" class="cursor-pointer text-base font-semibold leading-7 text-blue-500">Schedule a free consultation <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
      </div>
      <div class="mx-0 mt-14 max-w-4xl divide-y divide-white/10">
        <h2 class="text-2xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
        <dl class="mt-10 space-y-6 divide-y divide-white/10">
          <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt>
              <DisclosureButton class="flex w-full items-start justify-between text-left text-white">
                <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
                <span class="ml-6 flex h-7 items-center">
                  <PlusIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base leading-7 text-gray-300">{{ faq.answer }}</p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
  <ConsultationDisclaimer v-if="consultation" :consultation="this.consultation" @redirect="closeConsultation($event)"/>
</template>

<script>

  import { ref } from 'vue'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { MinusSmIcon, PlusIcon } from '@heroicons/vue/outline'
  import ConsultationDisclaimer from './ConsultationDisclaimer.vue'
  
  export default {
  name: 'ExposureManagement',

  components: {
    Disclosure, DisclosureButton, DisclosurePanel,
    MinusSmIcon, PlusIcon,
    ConsultationDisclaimer
  },

  data() {
      return {
        openItem: false,
        hide: false,
        consultation: false,
        faqs: [
        {
          question: 'In what ways do your technical security assessments differ from a traditional penetration test?',
          answer: 'Penetration tests typically aim to identify and exploit specific vulnerabilities in applications or systems, often by simulating the tactics and techniques of a potential attacker looking for the easiest path to breach security measures. Our methodology offers a comprehensive assessment of our clients\' security posture, taking into account all possible control gaps in their environment. We evaluate security settings, test for internal and external weaknesses, scrutinize processes, and gauge the potential impact on future client ventures.',
        },
    ],


      }
    },
    props: {
        spinner: Boolean,
    },

    methods: {
        startConsultation() {
          this.consultation = true
        },
        closeConsultation() {
          this.consultation = false      
        },
    },
  
  setup() {
  const mobileMenuOpen = ref(false)

  return {
    mobileMenuOpen
  }
  }
}
</script>