<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div v-if="cookieBanner" class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="p-2 rounded-lg bg-gray-100 shadow-lg sm:p-3">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex p-2 rounded-lg bg-gray-900">
              <SpeakerphoneIcon class="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <p class="ml-3 font-medium text-gray-800">
              <span class="font-bold text-sm"> This site does not use cookies. </span>
              <span class="hidden md:inline text-sm"> Check out our <span class="cursor-pointer font-bold" @click="goToPrivacyPolicy">Privacy Policy to learn more.</span> </span>
            </p>
          </div>
          <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
            <button @click="close" type="button" class="-mr-1 flex p-2 rounded-md hover:bg-gray-900  focus:outline-none ">
              <span class="sr-only">Dismiss</span>
              <XIcon class="h-6 w-6 text-gray-900 hover:text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SpeakerphoneIcon, XIcon } from '@heroicons/vue/outline'

export default {
  name: 'Banner',
  components: {
    SpeakerphoneIcon,
    XIcon,
  },

  data() {
      return {
          cookieBanner: true,
      }
  },

  methods: {
    goToPrivacyPolicy() {
      this.$router.push(`/privacy-policy`)
    },
    close() {
      this.cookieBanner = !this.cookieBanner
    }
  }
}
</script>