<template>
  <TransitionRoot v-if="consultation" as="template" :show="toggleModal">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="toggleModal">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <ExternalLinkIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> You will be redirected to Calendly.com </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">You are about to enter the calendly.com website hosted and owned by Calendly. BetterCyber uses Calendly to schedule free consultations. By clicking the <span class="font-bold text-black">Continue</span> button, you are acknowledging that you are leaving BetterCyber website and proceeding to the Calendly website.</p>
                </div>
              </div>
            </div>
          <div class="sm:col-span-2">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <Switch v-model="agreed" :class="[agreed ? 'bg-blue-600' : 'bg-gray-100', 'mt-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                  <span class="sr-only">Agree to policies</span>
                  <span aria-hidden="true" :class="[agreed ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
              </div>
              <div class="ml-3 mt-4">
                <p class="text-sm text-gray-500">
                  By selecting this, you agree to the
                  {{ ' ' }}
                  <a href="/privacy-policy" class="font-medium text-blue-500 underline">Privacy Policy</a>
                  {{ ' ' }}
                  and
                  {{ ' ' }}
                  <a href="/cookie-policy" class="font-medium text-blue-500 underline">Cookie Policy</a>.
                </p>
              </div>
            </div>
            <div v-if="submitted & !v$.agreed.$model" class="sm:col-span-2 mt-4 rounded-md bg-red-50 p-4">
                <div class="flex">
                <div class="flex-shrink-0">
                    <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <div class="text-sm text-red-700">
                        Did you read our Privacy and Cookie policies? 
                    </div>
                </div>
                </div>
            </div>
          </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button @click="redirect" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-900 hover:bg-gray-200 hover:text-gray-900 border-gray-300 hover:border-white focus:outline-none sm:text-sm">Cancel</button>     
              <button @click="goToScheduling" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-white hover:text-gray-900 focus:outline-none sm:text-sm">Continue</button>
         
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExternalLinkIcon } from '@heroicons/vue/outline'
import useValidate from "@vuelidate/core";
import { sameAs } from "@vuelidate/validators";
import { XCircleIcon } from '@heroicons/vue/solid';
import { Switch } from '@headlessui/vue';

export default {
  name: 'ConsultationDisclaimer',
  emits: ["redirect"],
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExternalLinkIcon,
    XCircleIcon,
    Switch
  },
  data() {
      return {
          toggleModal: true,
          v$: useValidate(),
          agreed: false,
          submitted: false,
      }
  },

  validations() {
      return {
        agreed: { sameAs: sameAs(() => true )},
        }
    },

  props: {
      consultation: Boolean,
  },

  methods: {

      redirect() {
          this.toggleModal = !this.toggleModal
          this.$emit('redirect')
      },
      goToScheduling() {
        this.submitted = !this.submitted
        this.v$.$touch()
          if (this.v$.agreed.$model) {
          window.location.href = "https://calendly.com/bettercyber";
          } 
        
        },

      validations() {
          return {};
      }
      }, 
}
</script>