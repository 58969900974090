import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import CookiePolicy from '../views/CookiePolicy.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import AboutUs from '../views/AboutUs.vue'
import Services from '../views/Services.vue'
import ProductIdeation from '../views/ProductIdeation.vue'
import ConsultingAndAdvisory from '../views/ConsultingAndAdvisory.vue'
import EngineeringAndOperations from '../views/EngineeringAndOperations.vue'
import ExposureManagement from '../views/ExposureManagement.vue'
import CloudIncidentResponse from '../views/CloudIncidentResponse.vue'
import ThreatIntelligence from '../views/ThreatIntelligence.vue'
import NotFound from '../views/NotFound.vue'

//import Tracker from '../views/Tracker.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicyPage',
    component: CookiePolicy
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicy
  },
  {
    path: '/services',
    name: 'ServicesPage',
    component: Services
  },
  {
    path: '/services/cybersecurity-product-ideation-and-validation',
    name: 'ProductIdeationPage',
    component: ProductIdeation
  },
  {
    path: '/services/cybersecurity-consulting-and-advisory',
    name: 'ConsultingAndAdvisoryPage',
    component: ConsultingAndAdvisory
  },
  {
    path: '/services/cybersecurity-engineering-and-operations',
    name: 'EngineeringAndOperationsPage',
    component: EngineeringAndOperations
  },
  {
    path: '/services/cybersecurity-exposure-management',
    name: 'ExposureManagementPage',
    component: ExposureManagement
  },
  {
    path: '/services/cloud-incident-response',
    name: 'CloudIncidentResponsePage',
    component: CloudIncidentResponse
  },
  {
    path: '/services/cyber-threat-intelligence',
    name: 'ThreatIntelligencePage',
    component: ThreatIntelligence
  },
  {
    path: '/about',
    name: 'AboutUsPage',
    component: AboutUs
  },
  {
    path: '/error',
    name: 'NotFoundPage',
    component: NotFound
  },

  // {
  //   path: '/incident-tracker',
  //   name: 'TrackerPage',
  //   component: Tracker
  // },
  { 
    path: '/:pathMatch(.*)*', 
  //  component: NotFound,
    redirect: () => {
        return { path: '/error'}
    } 
},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

})

export default router
