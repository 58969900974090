<template>
  <div>
    <CloudIncidentResponse />
  </div>
</template>

<script>
import CloudIncidentResponse from '@/components/CloudIncidentResponse.vue'

export default {
  name: 'CloudIncidentResponsePage',
  components: {
    CloudIncidentResponse
  }
}
</script>
