<template>
  <div class="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
    <div class="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56" aria-hidden="true">
      <div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#3bebb0] to-[#1b382e] opacity-30" style="clip-path: polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)" />
    </div>
    <div class="text-left mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <p class="text-lg font-semibold leading-8 tracking-tight text-blue-500">Expert guidance for success</p>
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Consulting & Advisory</h1>
        <p class="mt-6 text-xl text-left leading-8 text-gray-300">At our company, we understand that cybersecurity is vital, but we also know that profitability is equally important.</p>
      </div>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
        <div class="relative lg:order-last lg:col-span-5">
          <svg class="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]" aria-hidden="true">
            <defs>
              <pattern id="e87443c8-56e4-4c20-9111-55b82fa704e3" width="200" height="200" patternUnits="userSpaceOnUse">
                <path d="M0.5 0V200M200 0.5L0 0.499983" />
              </pattern>
            </defs>
            <rect width="100%" height="100%" stroke-width="0" fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" />
          </svg>
          <figure class="border-l border-blue-500 pl-8">
            <blockquote class="text-xl font-semibold leading-8 tracking-tight text-gray-300">
              <p>“By failing to prepare, you are preparing to fail.”</p>
            </blockquote>
            <figcaption class="mt-8 flex gap-x-4">
              <div class="text-sm leading-6">
                <div class="font-semibold text-white">Benjamin Franklin</div>
              </div>
            </figcaption>
          </figure>
        </div>
        <div class="max-w-xl text-left text-base leading-7 text-gray-300 lg:col-span-7">
          <p>With this in mind, we develop customized security strategies, programs, policies, plans, and management artifacts for each client, taking into account their unique business needs and circumstances. By doing so, we ensure that our clients can safeguard their businesses against cybersecurity risks and threats while maintaining their profitability. Our goal with this service is to provide the following:</p>
          <ul role="list" class="mt-8 text-left max-w-xl space-y-8 text-gray-300">
            <li class="flex gap-x-3">
              <font-awesome-icon icon="fa-duotone fa-pen-nib" class="mt-1 h-5 w-5 flex-none text-blue-500" aria-hidden="true" />
              <span>Design and implementation of <strong class="font-semibold text-white">cybersecurity strategies, programs, policies, procedures, and frameworks.</strong></span>
            </li>
            <li class="flex gap-x-3">
              <font-awesome-icon icon="fa-duotone fa-list-check" class="mt-1 h-5 w-5 flex-none text-blue-500" aria-hidden="true" />
              <span>Regulatory and compliance <strong class="font-semibold text-white"> readiness assessments.</strong> </span>
            </li>
            <li class="flex gap-x-3">
              <font-awesome-icon icon="fa-duotone fa-chart-mixed" class="mt-1 h-5 w-5 flex-none text-blue-500" aria-hidden="true" />
              <span><strong class="font-semibold text-white">Performance tracking </strong>for cybersecurity programs. </span>
            </li>
            <li class="flex gap-x-3">
              <font-awesome-icon icon="fa-duotone fa-infinity"  class="mt-1 h-5 w-5 flex-none text-blue-500" aria-hidden="true" />
              <span><strong class="font-semibold text-white"> Business continuity </strong>planning.</span>
            </li>
            <li class="flex gap-x-3">
              <font-awesome-icon icon="fa-duotone fa-siren-on"  class="mt-1 h-5 w-5 flex-none text-blue-500" aria-hidden="true" />
              <span>Incident response <strong class="font-semibold text-white"> tabletop exercise planning.</strong></span>
            </li>
            <li class="flex gap-x-3">
              <font-awesome-icon icon="fa-duotone fa-book-open-reader"  class="mt-1 h-5 w-5 flex-none text-blue-500" aria-hidden="true" />
              <span><strong class="font-semibold text-white"> Cybersecurity awareness and training </strong> planning and implementation.</span>
            </li>
          </ul>
          <div class="mt-10 flex">
            <a  @click="startConsultation" class="cursor-pointer text-base font-semibold leading-7 text-blue-500">Schedule a free consultation <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
      </div>
      <div class="mx-0 mt-14 max-w-4xl divide-y divide-white/10">
        <h2 class="text-2xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
        <dl class="mt-10 space-y-6 divide-y divide-white/10">
          <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt>
              <DisclosureButton class="flex w-full items-start justify-between text-left text-white">
                <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
                <span class="ml-6 flex h-7 items-center">
                  <PlusIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base leading-7 text-gray-300">{{ faq.answer }}</p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
  <ConsultationDisclaimer v-if="consultation" :consultation="this.consultation" @redirect="closeConsultation($event)"/>
</template>

<script>

  import { ref } from 'vue'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { MinusSmIcon, PlusIcon } from '@heroicons/vue/outline'
  import ConsultationDisclaimer from './ConsultationDisclaimer.vue'
  
  export default {
  name: 'ConsultingAndAdvisory',

  components: {
    Disclosure, DisclosureButton, DisclosurePanel,
    MinusSmIcon, PlusIcon,
    ConsultationDisclaimer
  },

  data() {
      return {
        openItem: false,
        hide: false,
        consultation: false,
        faqs: [
        {
          question: 'Who needs this service?',
          answer: 'We provide consulting and advisory services to a diverse range of clients, including those who are looking to establish formal security programs, startups targeting enterprise clients, businesses seeking cyber insurance coverage, organizations looking to obtain specific security certifications, and companies that must meet regulatory security standards, such as government contractors and subcontractors.',
        },
        {
          question: 'Which industry frameworks and standards are you able to provide consulting services for?',
          answer: 'We have experience working with a wide range of industry frameworks and standards. Some of the most common ones that our clients seek assistance with include NIST Cybersecurity Framework, NIST SP 800-53, NIST SP 800-171, CMMC, PCI DSS, ISO 27001, CIS Critical Controls, HIPAA, GDPR, and CCPA.',
        },
        {
          question: 'Do you offer policy and procedure templates?',
          answer: 'Yes. We provide a wide range of policy and procedure templates for various industry frameworks and standards.',
        },
        {
          question: 'What industry and regulatory standards do you offer readiness assessments for?',
          answer: 'We offer compliance readiness assessments for a wide range of industry standards, like CMMC, PCI DSS, NYDFS, and ISO 27001.',
        },
    ],


      }
    },
    props: {
        spinner: Boolean,
    },

    methods: {
        startConsultation() {
          this.consultation = true
        },
        closeConsultation() {
          this.consultation = false      
        },
    },
  
  setup() {
  const mobileMenuOpen = ref(false)

  return {
    mobileMenuOpen
  }
  }
}
</script>