<template>
  <div>
    <ConsultingAndAdvisory />
  </div>
</template>

<script>
import ConsultingAndAdvisory from '@/components/ConsultingAndAdvisory.vue'

export default {
  name: 'ConsultingAndAdvisoryPage',
  components: {
    ConsultingAndAdvisory
  }
}
</script>
