<template>
  <TransitionRoot v-if="github" as="template" :show="toggleModal">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="toggleModal">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
            <div>
             <div v-if="!spinner" class="mx-auto flex items-center justify-center h-12 w-12 rounded-full">
                <ExternalLinkIcon  v-if="!spinner"  class="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div v-if="spinner" class="mx-auto flex items-center justify-center h-12 w-12 rounded-full">
                <orbit-spinner v-if="spinner"
                  :animation-duration="2000"
                  :size="70"
                  color="#46dfbe"/>
              </div>
              <div class="mt-2 text-center sm:mt-3">
                <DialogTitle as="h3" class="text-lg leading-6 font-normal text-gray-900"> You will be redirected to <span class="font-bold text-black">GitHub.com</span>   </DialogTitle>
                <div class="mt-4 py-3">
                  <p class="text-sm font-normal text-gray-500">By clicking the <span class="font-bold text-black">Continue</span> button, you acknowledge that you are leaving BetterCyber website and proceeding to GitHub's website, hosted and owned by GitHub.</p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button @click="redirect" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-900 hover:bg-gray-200 hover:text-gray-900 border-gray-300 hover:border-white focus:outline-none sm:text-sm">Cancel</button>     
              <button @click="goToGithub" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-white hover:text-gray-900 focus:outline-none sm:text-sm">Continue</button>
         
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExternalLinkIcon } from '@heroicons/vue/outline'
import { OrbitSpinner } from 'epic-spinners'

export default {
  name: 'GithubDisclaimer',
  emits: ["redirect"],
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExternalLinkIcon,
    OrbitSpinner
  },
  data() {
      return {
          toggleModal: true,
          spinner: false,
      }
  },


  props: {
      github: Boolean,
  },

  methods: {

      redirect() {
          this.toggleModal = !this.toggleModal
          this.$emit('redirect')
      },
      goToGithub() {
        this.spinner = true,
        setTimeout(() => window.location.href = "https://github.com/bettercyber", 1000);
          
      },

  },
 
}
</script>