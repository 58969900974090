<template>
  <CookiePolicy />
</template>

<script>
import CookiePolicy from '../components/CookiePolicy.vue'
  export default {
        name: 'CookiePolicyPage',
        components: {
          CookiePolicy
        }
    
  }
</script>

<style lang="scss" scoped>

</style>
