<template>
  <div>
    <ThreatIntelligence />
  </div>
</template>

<script>
import ThreatIntelligence from '@/components/ThreatIntelligence.vue'

export default {
  name: 'ThreatIntelligencePage',
  components: {
    ThreatIntelligence
  }
}
</script>
