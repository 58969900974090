
<template>
    <div class="bg-gray-900">
      <div class="bg-gray-900 py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:text-center">
      <h2 class="text-base font-semibold leading-7 text-blue-500">Secure quickly, grow faster</h2>
      <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Everything you need to protect your business</p>
      <p class="mt-6 text-lg leading-8 text-gray-300">A comprehensive suite of cybersecurity services to unleash your business' full security potential.</p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none text-left ">
      <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
        <div class="flex flex-col">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
            <font-awesome-icon icon="fa-duotone fa-circle-info" class=" h-6 w-6 text-blue-500"/>
            Consulting & Advisory
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
            <p class="flex-auto ">Our consulting and advisory services are centered around the management elements of cybersecurity, such as planning, policy formulation, performance tracking, continual improvement, and information risk management. </p>
            <p class="mt-6">
              <a  @click="goToConsultingAndAdvisory" class="cursor-pointer text-sm font-semibold leading-6 text-blue-500">Learn more <span aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>

        <div class="flex flex-col">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
            <font-awesome-icon icon="fa-duotone fa-gears" class=" h-6 w-6 text-blue-500"/>
            Engineering & Operations
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
            <p class="flex-auto">Our engineering and operations service pillar focuses on both the technological and operational aspects of cybersecurity. Our services include technology implementation, optimization, management, and monitoring. </p>
            <p class="mt-6">
              <a  @click="goToEngineeringAndOperations" class="cursor-pointer text-sm font-semibold leading-6 text-blue-500">Learn more <span aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>

        <div class="flex flex-col">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
            <font-awesome-icon icon="fa-duotone fa-shield-virus" class=" h-6 w-6 text-blue-500"/>
            Exposure Management
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
            <p class="flex-auto">Our exposure management service focuses on identifying all potential weak spots in your applications and their underlying infrastructure. This comprehensive service includes web app penetration testing, technical assessments, and remediation services.</p>
            <p class="mt-6">
              <a  @click="goToExposureManagement" class="cursor-pointer text-sm font-semibold leading-6 text-blue-500">Learn more <span aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>
        <div class="flex flex-col">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
            <font-awesome-icon icon="fa-duotone fa-rocket-launch" class=" h-6 w-6 text-blue-500"/>
            Product Ideation & Validation
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
            <p class="flex-auto">We help startups and enterprises ideate, design, and validate their cybersecurity products and services, focusing on meeting the unique requirements of their intended audience. Our services focus exclusively on ideation, design, and validation and do not encompass cybersecurity software development.</p>
            <p class="mt-6">
              <a  @click="goToProductIdeation" class="cursor-pointer text-sm font-semibold leading-6 text-blue-500">Learn more <span aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>
        <div class="flex flex-col">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
            <font-awesome-icon icon="fa-duotone fa-cloud-check" class=" h-6 w-6 text-blue-500"/>
            Cloud Incident Response
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
            <p class="flex-auto">Our incident response service specializes in resolving incidents within cloud environments, encompassing SaaS, PaaS, and IaaS. We provide both on-demand incident response services and incident response retainers, differentiating ourselves from other providers by focusing solely on cloud-related incidents.</p>
            <p class="mt-6">
              <a  @click="goToCloudIncidentResponse" class="cursor-pointer text-sm font-semibold leading-6 text-blue-500">Learn more <span aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>
        <div class="flex flex-col">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
            <font-awesome-icon icon="fa-duotone fa-lightbulb-exclamation-on" class=" h-6 w-6 text-blue-500"/>
            Threat Intelligence
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
            <p class="flex-auto">At our company, we have our own cybersecurity threat intelligence platform called Venari, which provides detailed information on over 5,000 security incidents worldwide. Our platform constantly monitors more than 88 threat groups and is updated in near real-time with the latest information on new cyberattacks and data breaches.</p>
            <p class="mt-6">
              <a @click="goToThreatIngelligence" class="cursor-pointer text-sm font-semibold leading-6 text-blue-500">Learn more <span aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>
    </div>
    <ComingSoon v-if="openItem" :open-item="this.openItem" @close-modal="closeMenuItem($event)" />
  </template>
  
  <script>
  import { ref } from 'vue'
  import ComingSoon from './ComingSoon.vue'
  
  export default {
  name: 'Services',

  components: {
    ComingSoon
  },

  data() {
      return {
        openItem: false,
        hide: false,
        stats: [
            { label: 'Business was founded', value: '2019' },
            { label: 'People on the team', value: '5+' },
            { label: 'Clients we helped', value: '88+' },
            { label: 'People follow us', value: '10k+' },
      ],
        values: [
        {
        name: 'Be genuine.',
        description: 'We never push unnecessary products, use high-pressure sales tactics, or exaggerate the benefits of our services.',
        icon: 'fa-duotone fa-stars',
        },
        {
        name: 'Be transparent.',
        description: 'Our pricing model is simple and fair; you never pay more than we quote.',
        icon: 'fa-duotone fa-hand-holding-dollar',
        },
        {
        name: 'Be supportive.',
        description: 'Our customers\' needs and budgets are our main priority.',
        icon: 'fa-duotone fa-handshake-angle',
        },
        {
        name: 'Share what we know.',
        description: 'Your initial consultation is always free. We don\'t charge to answer questions.',
        icon: 'fa-duotone fa-graduation-cap',
        },
        {
        name: 'Bring the best of the best.',
        description: 'We bring top-quality talent to every project.',
        icon: 'fa-duotone fa-sun-bright',
        },
        {
        name: 'Be humble.',
        description: 'We know our limitations and recognize that knowledge is endless.',
        icon: 'fa-duotone fa-shield-heart',
        },
    ],
    benefits: [
        'Competitive compensation',
        'Set your own schedule',
        'Create without constraints',
        'Choose your own work location',
        ],
    linkedin: false

      }
    },
    props: {
        spinner: Boolean,
    },

    methods: {
        openSpinner() {
            this.hide = true
    },
        closeLinkedIn() {
            this.linkedin = false
          },
        openDisclaimer() {
            this.linkedin = true
          },
        closeMenuItem() {
            this.openItem = false
      },
        openMenuItem() {
            this.openItem = !this.openItem
        
      },
        goToProductIdeation() {
          this.$router.push(`/services/cybersecurity-product-ideation-and-validation`).then(() => {
          window.scrollTo(0, 0);
        });
        },
        goToConsultingAndAdvisory() {
          this.$router.push(`/services/cybersecurity-consulting-and-advisory`).then(() => {
          window.scrollTo(0, 0);
        });
        },
        goToEngineeringAndOperations() {
          this.$router.push(`/services/cybersecurity-engineering-and-operations`).then(() => {
          window.scrollTo(0, 0);
        });
        },
        goToExposureManagement() {
          this.$router.push(`/services/cybersecurity-exposure-management`).then(() => {
          window.scrollTo(0, 0);
        });
        },
        goToCloudIncidentResponse() {
          this.$router.push(`/services/cloud-incident-response`).then(() => {
          window.scrollTo(0, 0);
        });
        },
        goToThreatIngelligence() {
          this.$router.push(`/services/cyber-threat-intelligence`).then(() => {
          window.scrollTo(0, 0);
        });
        }
    },
  
  setup() {
  const mobileMenuOpen = ref(false)

  return {
    mobileMenuOpen
  }
  }
}
</script>