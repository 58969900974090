<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <footer v-if="!spinner" class="bg-gray-900">
    <div class="max-w-7xl mx-auto py-8 px-4 overflow-hidden sm:px-6 lg:px-8">
      <div style="border-top: 0.5px solid white; opacity: 0.2;"  class="border-t border-white/20 pt-8 md:flex md:items-center md:justify-between"/>
      <nav class="-mx-5 -my-6 flex flex-wrap justify-center" aria-label="Footer">
        <div v-for="item in navigation.main" :key="item.name" class="px-5 py-2">
          <a @click="openPolicy(item.href)" class="cursor-pointer text-sm text-gray-400 hover:text-gray-50">
            {{ item.name }}
          </a>
        </div>
      </nav>
      <div class="mt-8 flex justify-center space-x-6">
        <a v-for="item in navigation.social" :key="item.name"  class="text-gray-400 hover:text-gray-50">
          <span class="sr-only">{{ item.name }}</span>
          <component @click="openDisclaimer(item.name)" :is="item.icon" class="cursor-pointer h-6 w-6" aria-hidden="true" />
        </a>
      </div>
      <p class="mt-4 text-center text-sm text-gray-400">&copy; 2023 BetterCyber Consulting Group LLC. All rights reserved.</p>
    </div>
  </footer>
  <TwitterDisclaimer v-if="twitter" :twitter="this.twitter" @redirect="closeTwitter($event)"/>
  <LinkedInDisclaimer v-if="linkedin" :linkedin="this.linkedin" @redirect="closeLinkedIn($event)"/>
  <GithubDisclaimer v-if="github" :github="this.github" @redirect="closeGithub($event)"/>
  <TelegramDisclaimer v-if="telegram" :telegram="this.telegram" @redirect="closeTelegram($event)"/>
  <div v-if="spinner" class="mx-auto -mb-96 relative bg-gray-900 overflow-hidden flex flex-col min-h-screen items-center justify-center">
      <orbit-spinner v-if="spinner"
      :animation-duration="2000"
      :size="70"
      color="#46dfbe"/>
  </div>
</template>

<script>
import { defineComponent, h } from 'vue'
import TwitterDisclaimer from './TwitterDisclaimer.vue'
import LinkedInDisclaimer from './LinkedInDisclaimer.vue'
import GithubDisclaimer from './GithubDisclaimer.vue'
import TelegramDisclaimer from './TelegramDisclaimer.vue'
import { OrbitSpinner } from 'epic-spinners'

const navigation = {
  main: [
    { name: 'Home', href: '' },
    { name: 'Privacy Policy', href: 'privacy-policy' },
    { name: 'Cookie Policy', href: 'cookie-policy' },
  ],
  social: [
    {
      name: 'Twitter',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 22 22' }, [
            h('path', {
              d: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
            }),
          ]),
      }),
    },
    {
      name: 'GitHub',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 22 22' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'LinkedIn',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 16 16' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Telegram',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 496 512' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },

  ],
}

export default {
  name: 'Footer',
  emits: ["spinner"],
  components: {
    TwitterDisclaimer,
    LinkedInDisclaimer,
    GithubDisclaimer,
    OrbitSpinner,
    TelegramDisclaimer,
},

  data() {
      return {
          linkedin: false,
          twitter: false,
          github: false,
          spinner: false,
          telegram: false,
      }
  },
  props: {
    redirect: Boolean,
  },
  methods: {
      openDisclaimer(site) {
          if(site == 'Twitter') {
              this.twitter = true
          }
          if(site == 'LinkedIn') {
              this.linkedin = true
          }
          if(site == 'GitHub') {
              this.github = true
          }
          if(site == 'Telegram') {
              this.telegram = true
          }
      },
      closeTwitter() {
          this.twitter = false
          },
      closeTelegram() {
          this.telegram = false
          },          
      closeLinkedIn() {
          this.linkedin = false
          },
      closeGithub() {
          this.github = false
          },
      openPolicy(link) {
      //    this.spinner = true
      //    this.$emit('spinner')

            this.$router.push(`/${link}`);


      }
  },

  setup() {
    return {
      navigation,
    }
  },
}
</script>